import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './carousel.css';

const slides = [
  { path: '/main-content/intro', label: 'Tournament Countries Introduction' },
  { path: '/main-content/prediction/euro', label: 'UEFA Euro Predictions' },
  { path: '/main-content/prediction/copa', label: 'Copa América Predictions' },
  { path: '/main-content/group-tables', label: 'Projected Group Tables' },
  { path: '/main-content/leaderboard', label: 'Leaderboard' },
  { path: '/main-content/instructions', label: 'Instructions' },
];

const Carousel = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);

  useEffect(() => {
    const currentPathIndex = slides.findIndex(slide => slide.path === location.pathname);
    if (currentPathIndex !== -1) {
      setCurrentSlide(currentPathIndex);
    }
  }, [location.pathname]);

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
    navigate(slides[nextSlide].path);
  };

  const handlePrev = () => {
    const prevSlide = (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(prevSlide);
    navigate(slides[prevSlide].path);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      handleNext();
    } else if (touchStartX - touchEndX < -50) {
      handlePrev();
    }
  };

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="carousel-content">
        <h2 className="carousel-title">{slides[currentSlide].label}</h2>
      </div>
      <div className="carousel-nav-container">
        <button className="carousel-button carousel-button-prev" onClick={handlePrev}>
          &lt;
        </button>
        <div className="carousel-index">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`carousel-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => {
                setCurrentSlide(index);
                navigate(slides[index].path);
              }}
            />
          ))}
        </div>
        <button className="carousel-button carousel-button-next" onClick={handleNext}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
