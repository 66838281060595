import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    console.log('Requesting password reset for:', email);
    try {
      const response = await fetch(`${baseUrl}/request-password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      console.log('Response status:', response.status);
      
      // Try to parse the response as JSON
      const data = await response.json().catch(() => ({ message: 'Password reset link sent to your email.' }));

      console.log('Response data:', data);

      if (response.ok) {
        setMessage('Password reset link sent to your email.');
      } else {
        setMessage(data.message || 'Failed to request password reset.');
      }
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handlePasswordResetRequest}>
        <h2>Request Password Reset</h2>
        {message && <div className="auth-message">{message}</div>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Reset Link</button>
      </form>
      <button className="back-button" onClick={() => navigate('/login')}>Back</button>
    </div>
  );
};

export default PasswordResetRequest;
