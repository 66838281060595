import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { AuthContext } from '../../context/AuthContext';
import './GroupTables.css';

const slideBlurb = {
  "Group Tables": "Based on your predictions, this is how you predict the group stages play out. It should give you an idea of how you think the tournaments play out in the big picture. Make sure you have predicted all games!",
  // Add other tournament blurbs here
};

const standardizeTournamentName = (tournament) => {
  if (tournament.includes('Euro')) {
    return 'UEFA Euro';
  }
  if (tournament.includes('Copa')) {
    return 'Copa America';
  }
  return tournament;
};

const GroupTables = () => {
  const [groupTables, setGroupTables] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const [activeTournament, setActiveTournament] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

  useEffect(() => {
    const fetchPredictions = async () => {
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/api/predictions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch predictions');
        }

        const predictions = await response.json();
        console.log('Fetched predictions:', predictions);
        const groupTables = calculateGroupTables(predictions);
        setGroupTables(groupTables);

        // Set initial active tournament and group
        const initialTournament = Object.keys(groupTables)[0];
        const initialGroup = Object.keys(groupTables[initialTournament])[0];
        setActiveTournament(initialTournament);
        setActiveGroup(initialGroup);
      } catch (err) {
        console.error('Error fetching predictions:', err);
        setError('Failed to fetch predictions');
      } finally {
        setLoading(false);
      }
    };

    fetchPredictions();
  }, [token, baseUrl]);

  const calculateGroupTables = (predictions) => {
    const groupTables = {};

    predictions.forEach(prediction => {
      let { group, team1, team2, team1_score, team2_score, tournament } = prediction;

      if (!tournament || !group) {
        console.warn(`Invalid tournament or group data: Tournament - ${tournament}, Group - ${group}`);
        return;
      }

      tournament = standardizeTournamentName(tournament);

      if (!groupTables[tournament]) {
        groupTables[tournament] = {};
      }

      if (!groupTables[tournament][group]) {
        groupTables[tournament][group] = [];
      }

      // Add/update team1
      let team1Info = groupTables[tournament][group].find(team => team.name === team1);
      if (!team1Info) {
        team1Info = { name: team1, points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, wins: 0, losses: 0, ties: 0 };
        groupTables[tournament][group].push(team1Info);
      }
      team1Info.goalsFor += team1_score;
      team1Info.goalsAgainst += team2_score;
      team1Info.goalDifference = team1Info.goalsFor - team1Info.goalsAgainst;
      if (team1_score > team2_score) {
        team1Info.points += 3;
        team1Info.wins += 1;
      } else if (team1_score === team2_score) {
        team1Info.points += 1;
        team1Info.ties += 1;
      } else {
        team1Info.losses += 1;
      }

      // Add/update team2
      let team2Info = groupTables[tournament][group].find(team => team.name === team2);
      if (!team2Info) {
        team2Info = { name: team2, points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, wins: 0, losses: 0, ties: 0 };
        groupTables[tournament][group].push(team2Info);
      }
      team2Info.goalsFor += team2_score;
      team2Info.goalsAgainst += team1_score;
      team2Info.goalDifference = team2Info.goalsFor - team2Info.goalsAgainst;
      if (team2_score > team1_score) {
        team2Info.points += 3;
        team2Info.wins += 1;
      } else if (team2_score === team1_score) {
        team2Info.points += 1;
        team2Info.ties += 1;
      } else {
        team2Info.losses += 1;
      }
    });

    // Sort teams within each group
    for (const tournament in groupTables) {
      for (const group in groupTables[tournament]) {
        groupTables[tournament][group].sort((a, b) => {
          if (b.points !== a.points) return b.points - a.points;
          if (b.goalDifference !== a.goalDifference) return b.goalDifference - a.goalDifference;
          return b.goalsFor - a.goalsFor;
        });
      }

      // Sort groups alphabetically
      groupTables[tournament] = Object.keys(groupTables[tournament]).sort().reduce((acc, key) => {
        acc[key] = groupTables[tournament][key];
        return acc;
      }, {});
    }

    return groupTables;
  };

  const handleTournamentClick = (tournament) => {
    setActiveTournament(tournament);
    const initialGroup = Object.keys(groupTables[tournament])[0];
    setActiveGroup(initialGroup);
  };

  const handleGroupClick = (group) => {
    setActiveGroup(group);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container">
      <p className="slide-blurb">{slideBlurb["Group Tables"]}</p>
      <div className="tabs">
        {Object.keys(groupTables).map(tournament => (
          <button
            key={tournament}
            className={`tab ${activeTournament === tournament ? 'active' : ''}`}
            onClick={() => handleTournamentClick(tournament)}
          >
            {tournament}
          </button>
        ))}
      </div>
      {activeTournament && (
        <div className="tabs">
          {Object.keys(groupTables[activeTournament]).map(group => (
            <button
              key={group}
              className={`tab ${activeGroup === group ? 'active' : ''}`}
              onClick={() => handleGroupClick(group)}
            >
              {group}
            </button>
          ))}
        </div>
      )}
      {activeTournament && activeGroup && (
        <div className="tab-content active group-table-container">
          <GroupTable groupData={groupTables[activeTournament][activeGroup]} />
        </div>
      )}
    </div>
  );
};

const GroupTable = ({ groupData }) => {
  const columns = useMemo(
    () => [
      { Header: 'Team', accessor: 'name' },
      { Header: 'Points', accessor: 'points' },
      { Header: 'Wins', accessor: 'wins' },
      { Header: 'Losses', accessor: 'losses' },
      { Header: 'Ties', accessor: 'ties' },
      { Header: 'Goals For', accessor: 'goalsFor' },
      { Header: 'Goals Against', accessor: 'goalsAgainst' },
      { Header: 'Goal Difference', accessor: 'goalDifference' }
    ],
    []
  );

  const data = useMemo(() => groupData, [groupData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="group-table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default GroupTables;
