// /Users/khoff/Code/morning-sea-55371/client/src/context/storageUtils.js

// utility function to safely access localStorage
export const safeLocalStorage = () => {
    try {
      const storage = window.localStorage;
      const testKey = '__storage_test__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return storage;
    } catch (e) {
      return null;
    }
  };
  