import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import CopaPredictions from './components/CopaPredictions/CopaPredictions';
import EuroPredictions from './components/EuroPredictions/EuroPredictions';
import GroupTables from './components/GroupTables/GroupTables';
import Instructions from './components/Instructions/Instructions';
import FormattedInstructions from './components/Instructions/FormattedInstructions';
import Leaderboard from './components/Leaderboard/Leaderboard';
import PasswordResetRequest from './components/Auth/PasswordResetRequest';
import ResetPassword from './components/Auth/ResetPassword';
import SimpleMap from './components/SimpleMap/SimpleMap';
import Carousel from './components/Carousel/Carousel';
import { AuthContext } from './context/AuthContext';
import useAxiosWithAuth from './utils/useAxiosWithAuth';
import LogoutButton from './components/Auth/LogoutButton';
import Register from './components/Auth/Register'; // Import Register component

const ProtectedRoute = ({ element }) => {
  const { token } = useContext(AuthContext);
  return token ? element : <Navigate to="/login" />;
};

const App = () => {
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  useAxiosWithAuth(); // Ensure Axios interceptors are set up

  useEffect(() => {
    console.log('App component mounted');
    console.log('Token on app load:', token);
  }, [token]);

  useEffect(() => {
    // Show logout button if not on the initial instructions page and not on auth pages
    const authRoutes = ['/login', '/register', '/request-password-reset', '/reset-password'];
    setShowLogoutButton(!authRoutes.includes(location.pathname) && location.pathname !== '/instructions');
  }, [location.pathname]);

  return (
    <div className="app-container">
      {showLogoutButton && <LogoutButton />}
      {!token ? (
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/register" element={<Register />} />
          <Route path="/request-password-reset" element={<PasswordResetRequest />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/instructions" element={<Instructions showButton={true} />} />
          <Route path="/main-content/*" element={<MainContent />} />
          <Route path="*" element={<Navigate to="/instructions" />} />
        </Routes>
      )}
    </div>
  );
};

const MainContent = () => (
  <div className="main-content-container">
    <Carousel />
    <div className="content-container">
      <Routes>
        <Route path="/intro" element={<SimpleMap />} />
        <Route path="/prediction/euro" element={<ProtectedRoute element={<EuroPredictions />} />} />
        <Route path="/prediction/copa" element={<ProtectedRoute element={<CopaPredictions />} />} />
        <Route path="/group-tables" element={<ProtectedRoute element={<GroupTables />} />} />
        <Route path="/leaderboard" element={<ProtectedRoute element={<Leaderboard />} />} />
        <Route path="/instructions" element={<FormattedInstructions />} />
        <Route path="*" element={<Navigate to="/intro" />} />
      </Routes>
    </div>
  </div>
);

export default App;
