import React, { useMemo, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { scaleOrdinal } from 'd3-scale';

// Function to convert date to PST
const convertToPST = (dateString) => {
    const date = new Date(dateString);
    const pstTimezone = 'America/Los_Angeles';
    return new Intl.DateTimeFormat('en-US', {
        timeZone: pstTimezone,
        hour12: true,
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }).format(date);
};

const RaceChart = ({ leaderboard }) => {
    const [selectedUser, setSelectedUser] = useState(null);

    const data = useMemo(() => {
        if (!leaderboard || !Array.isArray(leaderboard)) {
            return [];
        }

        const filteredData = selectedUser
            ? leaderboard.filter(user => user.username === selectedUser)
            : leaderboard;

        return filteredData.map(user => ({
            id: user.username,
            data: user.pointsHistory.map((point, index) => ({
                x: convertToPST(point.x), // Convert to PST with date and time
                y: point.y,
                teams: point.teams,
                predictedScore: point.predictedScore,
                actualScore: point.actualScore,
                matchPoints: index === 0 ? point.y : point.y - user.pointsHistory[index - 1].y,
                totalPoints: point.y
            }))
        }));
    }, [leaderboard, selectedUser]);

    const colorScale = useMemo(() => {
        const usernames = leaderboard.map(user => user.username);
        return scaleOrdinal(schemeCategory10).domain(usernames);
    }, [leaderboard]);

    return (
        <div style={{ height: '400px' }}>
            <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 80, left: 60 }} // Adjust bottom margin to 80
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    stacked: false,
                    min: 'auto',
                    max: 'auto',
                    tickValues: 'every 1' // Ensure only integers are displayed
                }}
                gridYValues={[...Array(11).keys()]} // Ensure grid lines only appear at integer values from 0 to 10
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 45,
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Points',
                    legendOffset: -40,
                    legendPosition: 'middle',
                    tickValues: 'every 1' // Ensure only integers are displayed
                }}
                colors={d => colorScale(d.id)}
                colorBy="id" // Ensure color is determined by the id (username)
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                enableArea={true}
                enablePoints={true} // Ensure points are enabled for every game
                tooltip={({ point }) => (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <strong>{point.serieId}</strong>
                        <br />
                        <strong>{point.data.xFormatted}</strong>
                        <br />
                        {point.data.teams}
                        <br />
                        Predicted Score: {point.data.predictedScore}
                        <br />
                        Actual Score: {point.data.actualScore}
                        <br />
                        Match Points: {point.data.matchPoints}
                        <br />
                        Total Points: {point.data.totalPoints}
                    </div>
                )}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        onClick: data => {
                            setSelectedUser(selectedUser === data.id ? null : data.id);
                        }
                    }
                ]}
            />
        </div>
    );
};

export default RaceChart;
