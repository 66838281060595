import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { safeLocalStorage } from './storageUtils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const storage = safeLocalStorage();
  const [token, setToken] = useState(() => storage?.getItem('token') || null);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

  useEffect(() => {
    const checkToken = async () => {
      if (!token) return;

      try {
        const response = await axios.get(`${baseUrl}/api/check-token`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.data.valid) {
          setToken(null);
          storage?.removeItem('token');
          navigate('/login');
        }
      } catch (error) {
        console.log('Error checking token:', error);
        setToken(null);
        storage?.removeItem('token');
        navigate('/login');
      }
    };

    checkToken();
  }, [token, navigate, baseUrl]);

  const login = (newToken) => {
    setToken(newToken);
    storage?.setItem('token', newToken);
  };

  const logout = async () => {
    console.log('Logging out...');
    setToken(null);
    storage?.removeItem('token');
    try {
      await axios.post(`${baseUrl}/api/logout`, {}, { withCredentials: true });
    } catch (error) {
      console.log('Error during logout:', error);
    }
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
