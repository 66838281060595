import React from 'react';
import Flag from 'react-world-flags';
import './FlagPredictions.css';

const flagMapping = {
  "Argentina": "ARG",
  "Brazil": "BRA",
  "Chile": "CHL",
  "Colombia": "COL",
  "Paraguay": "PRY",
  "Peru": "PER",
  "Uruguay": "URY",
  "Venezuela": "VEN",
  "Canada": "CA",
  "Ecuador": "EC",
  "Mexico": "MX",
  "Jamaica": "JM",
  "USA": "US",
  "Bolivia": "BO",
  "Panama": "PA",
  "Costa Rica": "CR",
  "Paraguay": "PY",
  "Venezuela": "VE",
  "Albania": "AL",
  "Austria": "AT",
  "Belgium": "BE",
  "Croatia": "HR",
  "Czech Republic": "CZ",
  "Denmark": "DK",
  "England": "GB_ENG",
  "France": "FR",
  "Georgia": "GE",
  "Germany": "DE",
  "Hungary": "HU",
  "Italy": "IT",
  "Netherlands": "NL",
  "Poland": "PL",
  "Portugal": "PT",
  "Romania": "RO",
  "Scotland": "GB_SCT",
  "Serbia": "RS",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Spain": "ES",
  "Switzerland": "CHE",
  "Turkey": "TR",
  "Ukraine": "UA",
};


const FlagPredictions = ({ matches = [], predictions = {}, onPredictionSubmit, title }) => {
  const handleIncrement = (matchId, team) => {
    const existingPrediction = predictions[matchId] || { team1_score: 0, team2_score: 0 };
    const newScore = existingPrediction[team] + 1;
    onPredictionSubmit(matchId, team === 'team1_score' ? newScore : existingPrediction.team1_score, team === 'team2_score' ? newScore : existingPrediction.team2_score);
  };

  const handleDecrement = (matchId, team) => {
    const existingPrediction = predictions[matchId] || { team1_score: 0, team2_score: 0 };
    const newScore = Math.max(existingPrediction[team] - 1, 0);
    onPredictionSubmit(matchId, team === 'team1_score' ? newScore : existingPrediction.team1_score, team === 'team2_score' ? newScore : existingPrediction.team2_score);
  };

  const handleSubmit = (matchId) => {
    const prediction = predictions[matchId];
    if (prediction) {
      onPredictionSubmit(matchId, prediction.team1_score, prediction.team2_score);
    }
  };

  if (!matches.length) {
    return <div>Loading matches...</div>;
  }

  return (
    <div className="predictions-container">
      <h2>{title}</h2>
      {matches.map(match => (
        <div key={match.id} className="match-container">
          <div className="match-info">
            <p><strong>Group:</strong> {match.group} | <strong>Date:</strong> {new Date(match.match_time).toLocaleString()} | <strong>City:</strong> {match.match_location} | <strong>Stadium:</strong> {match.stadium}</p>
          </div>
          <div className="match-content">
            <div className="team-container">
              <button className="score-btn" onClick={() => handleIncrement(match.id, 'team1_score')}>+</button>
              <div className="flag-container" onClick={() => handleIncrement(match.id, 'team1_score')}>
                <Flag code={flagMapping[match.team1]} alt={match.team1} className="flag" />
              </div>
              <button className="score-btn" onClick={() => handleDecrement(match.id, 'team1_score')} disabled={(predictions[match.id]?.team1_score ?? 0) <= 0}>-</button>
            </div>
            <div className="score-country-container">
              <div className="country-name">{match.team1}</div>
              <div className="score-number">{predictions[match.id]?.team1_score ?? 0}</div>
            </div>
            <div className="submit-container">
              <button className={`submit-btn ${predictions[match.id] ? 'submit-btn-predicted' : 'submit-btn-not-predicted'}`} onClick={() => handleSubmit(match.id)}>Submit</button>
            </div>
            <div className="score-country-container">
              <div className="country-name">{match.team2}</div>
              <div className="score-number">{predictions[match.id]?.team2_score ?? 0}</div>
            </div>
            <div className="team-container">
              <button className="score-btn" onClick={() => handleIncrement(match.id, 'team2_score')}>+</button>
              <div className="flag-container" onClick={() => handleIncrement(match.id, 'team2_score')}>
                <Flag code={flagMapping[match.team2]} alt={match.team2} className="flag" />
              </div>
              <button className="score-btn" onClick={() => handleDecrement(match.id, 'team2_score')} disabled={(predictions[match.id]?.team2_score ?? 0) <= 0}>-</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlagPredictions;