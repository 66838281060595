import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Auth.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, token, newPassword: password }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Password updated successfully.');
        setMessage('Password updated successfully. Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000); // Redirect after 3 seconds
      } else {
        console.error(data.message);
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handlePasswordReset}>
        <h2>Reset Password</h2>
        {message && <div className="auth-message">{message}</div>}
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      <button className="back-button" onClick={() => navigate('/login')}>Back</button>
    </div>
  );
};

export default ResetPassword;
