import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import ProgressBar from '../ProgressBar/ProgressBar';
import Tabs from '../Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import FlagPredictions from '../FlagPredictions/FlagPredictions';
import euroLogo from '../../logos/eufa-euros.png';
import './EuroPredictions.css';

const EuroPredictions = () => {
  const [matches, setMatches] = useState([]);
  const [predictions, setPredictions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const { token, logout } = useContext(AuthContext);
  const isMounted = useRef(true);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

  useEffect(() => {
    const fetchMatchesAndPredictions = async () => {
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const matchResponse = await fetch(`${baseUrl}/api/matches`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!matchResponse.ok) {
          throw new Error('Failed to fetch matches');
        }

        const matchData = await matchResponse.json();

        const predictionResponse = await fetch(`${baseUrl}/api/predictions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!predictionResponse.ok) {
          throw new Error('Failed to fetch predictions');
        }

        const predictionData = await predictionResponse.json();
        const predictionMap = predictionData.reduce((acc, pred) => {
          acc[pred.match_id] = { ...pred, submitted: true };
          return acc;
        }, {});

        if (isMounted.current) {
          setMatches(matchData);
          setPredictions(predictionMap);
          calculateProgress(matchData, predictionMap);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (isMounted.current) {
          setError('Failed to fetch data');
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    fetchMatchesAndPredictions();

    return () => {
      isMounted.current = false;
    };
  }, [token, baseUrl]);

  const calculateProgress = (matches, predictions) => {
    const totalMatches = matches.filter(match => match.tournament.includes('Euro')).length;
    const predictedMatches = matches.filter(match => match.tournament.includes('Euro') && predictions[match.id]).length;
    setProgress((predictedMatches / totalMatches) * 100);
  };

  const handlePredictionSubmit = async (matchId, team1Score, team2Score) => {
    if (!token) {
      setError('No token found');
      logout();
      return;
    }

    team1Score = team1Score !== '' && team1Score !== undefined ? team1Score : 0;
    team2Score = team2Score !== '' && team2Score !== undefined ? team2Score : 0;

    const newPrediction = {
      matchId,
      team1Score,
      team2Score
    };

    console.log(`Submitting prediction for match ${matchId}: ${team1Score} vs ${team2Score}`);

    try {
      const response = await fetch(`${baseUrl}/api/predictions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newPrediction)
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setError('Token expired or invalid. Redirecting to login.');
          logout();
          return;
        }
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to submit prediction');
      }

      const data = await response.json();
      console.log('Prediction submitted successfully:', data);

      if (isMounted.current) {
        setPredictions(prevPredictions => {
          const updatedPredictions = {
            ...prevPredictions,
            [matchId]: {
              ...data,
              team1_score: data.team1_score,
              team2_score: data.team2_score,
              submitted: true
            }
          };
          calculateProgress(matches, updatedPredictions);
          return updatedPredictions;
        });
      }
    } catch (err) {
      console.error('Error submitting prediction:', err);
      setError(err.message);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'Match',
      accessor: d => `${d.team1} vs ${d.team2}`,
      id: 'match'
    },
    {
      Header: 'Prediction',
      accessor: 'prediction',
      Cell: ({ row }) => {
        const match = row.original;
        const existingPrediction = predictions[match.id] || { team1_score: '', team2_score: '', submitted: false };
        const matchStarted = new Date(match.match_time) <= new Date();

        return (
          <div className={`prediction-input ${matchStarted ? 'disabled-prediction' : ''}`}>
            <input
              type="number"
              placeholder={match.team1_flag}
              aria-label={`${match.team1} score`}
              className="team-score-input"
              value={existingPrediction.team1_score !== null ? existingPrediction.team1_score : ''}
              min={0}
              onChange={(e) => {
                if (!matchStarted) {
                  const value = e.target.value !== '' ? Math.max(0, parseInt(e.target.value)) : '';
                  setPredictions(prevPredictions => ({
                    ...prevPredictions,
                    [match.id]: {
                      ...prevPredictions[match.id],
                      team1_score: value,
                      submitted: false
                    }
                  }));
                }
              }}
              disabled={matchStarted}
            />
            <span className="vs-text">vs</span>
            <input
              type="number"
              placeholder={match.team2_flag}
              aria-label={`${match.team2} score`}
              className="team-score-input"
              value={existingPrediction.team2_score !== null ? existingPrediction.team2_score : ''}
              min={0}
              onChange={(e) => {
                if (!matchStarted) {
                  const value = e.target.value !== '' ? Math.max(0, parseInt(e.target.value)) : '';
                  setPredictions(prevPredictions => ({
                    ...prevPredictions,
                    [match.id]: {
                      ...prevPredictions[match.id],
                      team2_score: value,
                      submitted: false
                    }
                  }));
                }
              }}
              disabled={matchStarted}
            />
            <button
              className={existingPrediction.submitted ? "submit-button predicted" : "submit-button"}
              onClick={() => {
                if (!matchStarted) {
                  console.log(`Submitting scores for match ${match.id}: ${existingPrediction.team1_score} vs ${existingPrediction.team2_score}`);
                  handlePredictionSubmit(match.id, existingPrediction.team1_score, existingPrediction.team2_score);
                }
              }}
              disabled={matchStarted}
            >
              Submit
            </button>
          </div>
        );
      }
    },
    {
      Header: 'Group',
      accessor: 'group',
      className: 'expandable-content'
    },
    {
      Header: 'Date',
      accessor: d => new Date(d.match_time).toLocaleString(),
      id: 'date',
      className: 'expandable-content'
    },
    {
      Header: 'Location',
      accessor: 'match_location',
      className: 'expandable-content'
    },
    {
      Header: 'Stadium',
      accessor: 'stadium',
      className: 'expandable-content'
    },
    {
      Header: 'Result',
      accessor: d => d.score1 !== null && d.score2 !== null ? `${d.score1} - ${d.score2}` : 'TBD',
      id: 'result',
      className: 'expandable-content'
    }
  ], [predictions]);

  const data = useMemo(() => matches.filter(match => match.tournament.includes('Euro')), [matches]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data, initialState: { sortBy: [{ id: 'date', desc: false }] } }, useSortBy);

  const toggleExpandableContent = () => {
    document.querySelectorAll('.expandable-content').forEach(element => {
      element.classList.toggle('visible');
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="euro-predictions-container">
      <div className="slide-title">
        <img src={euroLogo} alt="Euro Logo" className="tournament-logo" />
      </div>
      <p className="tournament-blurb">The UEFA European Championship, commonly referred to as the Euros, is the primary football competition contested by the senior men's national teams of the members of the Union of European Football Associations (UEFA), determining the continental champion of Europe.</p>
      <div className="progress-container">
        <ProgressBar progress={Math.round(progress)} />
      </div>
      <Tabs>
        <div label="Tabular View">
          <table {...getTableProps()} className="predictions-table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const match = row.original;
                const matchStarted = new Date(match.match_time) <= new Date();
                return (
                  <tr {...row.getRowProps()} className={matchStarted ? 'past-match' : i % 2 === 0 ? 'even-row' : 'odd-row'}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button onClick={toggleExpandableContent}>Toggle Extra Details</button>
        </div>
        <div label="Visual View">
          <FlagPredictions
            matches={matches.filter(match => match.tournament.includes('Euro'))}
            predictions={predictions}
            onPredictionSubmit={handlePredictionSubmit}
            title="UEFA Euros Matches - Visual View"
          />
        </div>
      </Tabs>
    </div>
  );
};

export default EuroPredictions;
