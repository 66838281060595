import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { feature } from "topojson-client";
import './SimpleMap.css';

const geoUrl = "/topo_names_simplified_14.json";

const countryGroups = {
  "Copa America": {
    "Group A": [
      { name: "Argentina", iso: "ARG", rank: 1, population: 45200000 },
      { name: "Peru", iso: "PER", rank: 32, population: 33000000 },
      { name: "Chile", iso: "CHL", rank: 42, population: 19000000 },
      { name: "Canada", iso: "CAN", rank: 49, population: 38000000 },
    ],
    "Group B": [
      { name: "Mexico", iso: "MEX", rank: 14, population: 128000000 },
      { name: "Ecuador", iso: "ECU", rank: 31, population: 17600000 },
      { name: "Venezuela", iso: "VEN", rank: 54, population: 28000000 },
      { name: "Jamaica", iso: "JAM", rank: 55, population: 3000000 },
    ],
    "Group C": [
      { name: "United States of America", iso: "USA", rank: 11, population: 331000000 },
      { name: "Uruguay", iso: "URY", rank: 15, population: 3500000 },
      { name: "Panama", iso: "PAN", rank: 45, population: 4300000 },
      { name: "Bolivia", iso: "BOL", rank: 85, population: 11600000 },
    ],
    "Group D": [
      { name: "Brazil", iso: "BRA", rank: 5, population: 213000000 },
      { name: "Colombia", iso: "COL", rank: 12, population: 50800000 },
      { name: "Paraguay", iso: "PRY", rank: 56, population: 7130000 },
      { name: "Costa Rica", iso: "CRI", rank: 52, population: 5100000 },
    ],
  },
  "Euro": {
    "Group A": [
      { name: "Germany", iso: "DEU", rank: 16, population: 83100000 },
      { name: "Scotland", iso: "SCT", rank: 39, population: 5460000 },
      { name: "Hungary", iso: "HUN", rank: 26, population: 9700000 },
      { name: "Switzerland", iso: "CHE", rank: 19, population: 8600000 },
    ],
    "Group B": [
      { name: "Spain", iso: "ESP", rank: 8, population: 47300000 },
      { name: "Croatia", iso: "HRV", rank: 10, population: 4100000 },
      { name: "Italy", iso: "ITA", rank: 9, population: 60200000 },
      { name: "Albania", iso: "ALB", rank: 66, population: 2870000 },
    ],
    "Group C": [
      { name: "Slovenia", iso: "SVN", rank: 57, population: 2100000 },
      { name: "Denmark", iso: "DNK", rank: 21, population: 5820000 },
      { name: "Serbia", iso: "SRB", rank: 33, population: 6900000 },
      { name: "England", iso: "ENG", rank: 4, population: 55900000 },
    ],
    "Group D": [
      { name: "Poland", iso: "POL", rank: 28, population: 38300000 },
      { name: "Netherlands", iso: "NLD", rank: 7, population: 17400000 },
      { name: "Austria", iso: "AUT", rank: 25, population: 9000000 },
      { name: "France", iso: "FRA", rank: 2, population: 67300000 },
    ],
    "Group E": [
      { name: "Belgium", iso: "BEL", rank: 3, population: 11500000 },
      { name: "Slovakia", iso: "SVK", rank: 48, population: 5450000 },
      { name: "Romania", iso: "ROU", rank: 46, population: 19200000 },
      { name: "Ukraine", iso: "UKR", rank: 22, population: 44100000 },
    ],
    "Group F": [
      { name: "Turkey", iso: "TUR", rank: 40, population: 84300000 },
      { name: "Georgia", iso: "GEO", rank: 75, population: 3980000 },
      { name: "Portugal", iso: "PRT", rank: 6, population: 10200000 },
      { name: "Czechia", iso: "CZE", rank: 36, population: 10700000 },
    ],
  }
};

const groupColors = {
  "Copa America": {
    "Group A": "#a8e6a1",
    "Group B": "#68d46e",
    "Group C": "#2cb839",
    "Group D": "#0e8c1a",
  },
  "Euro": {
    "Group A": "#ffb3b3",
    "Group B": "#ff6666",
    "Group C": "#ff1a1a",
    "Group D": "#cc0000",
    "Group E": "#800000",
    "Group F": "#4d0000",
  }
};

const SimpleMap = () => {
  const [geographies, setGeographies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [filter, setFilter] = useState({ tournament: null, group: null, country: null });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [center, setCenter] = useState([12.971251597791488, -30.195707340023695]);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setZoom(1);
        setCenter([12.971251597791488, -30.195707340023695]);
      } else {
        setZoom(0.81);
        setCenter([16.847489977737535, -50.77916213593956]); // Updated coordinates for desktop
      }
    };

    handleResize(); // Set initial values
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchGeographies = async () => {
      try {
        const response = await fetch(geoUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch geographies");
        }
        const data = await response.json();

        const filteredFeatures = feature(data, data.objects.ne_10m_admin_0).features.filter(geo => {
          const iso = geo.properties.ISO_A3;
          const adm0_tlc = geo.properties.ADM0_TLC;

          if (adm0_tlc === 'GUF') {
            return false;
          }

          if (adm0_tlc === 'ENG' || adm0_tlc === 'SCT') {
            geo.properties.ISO_A3 = adm0_tlc;
          }

          if (iso === '-99' && adm0_tlc !== 'ENG' && adm0_tlc !== 'SCT') {
            return false;
          }

          return true;
        });

        if (isMounted) {
          setGeographies(filteredFeatures);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchGeographies();

    return () => {
      isMounted = false;
    };
  }, []);

  const getCountryColor = (iso) => {
    for (const tournament in countryGroups) {
      for (const group in countryGroups[tournament]) {
        if (countryGroups[tournament][group].some(c => c.iso === iso)) {
          return groupColors[tournament][group];
        }
      }
    }
    return "#E0E0E0"; // Default color
  };

  const getCountryInfo = (iso) => {
    for (const tournament in countryGroups) {
      for (const group in countryGroups[tournament]) {
        const foundCountry = countryGroups[tournament][group].find(c => c.iso === iso);
        if (foundCountry) {
          return { tournament, group, rank: foundCountry.rank, name: foundCountry.name, population: foundCountry.population };
        }
      }
    }
    return null;
  };

  const handleCountryClick = (iso) => {
    const countryInfo = getCountryInfo(iso);
    if (countryInfo) {
      if (selectedCountry && selectedCountry.iso === iso) {
        setSelectedCountry(null);
        setFilter({ ...filter, country: null });
      } else {
        setSelectedCountry({ iso, ...countryInfo });
        setFilter({ ...filter, country: iso });
      }
    }
  };

  const formatPopulation = (population) => {
    return `${(population / 1000000).toFixed(1)} million`;
  };

  const shouldDeemphasize = (iso) => {
    if (filter.country) {
      return filter.country !== iso;
    }
    if (filter.tournament) {
      if (filter.group) {
        return !countryGroups[filter.tournament][filter.group].some(c => c.iso === iso);
      }
      return !Object.keys(countryGroups[filter.tournament]).some(group =>
        countryGroups[filter.tournament][group].some(c => c.iso === iso)
      );
    }
    return selectedCountry ? selectedCountry.iso !== iso : false;
  };

  const handleResetZoom = () => {
    if (window.innerWidth <= 768) {
      setZoom(1);
      setCenter([12.971251597791488, -30.195707340023695]);
    } else {
      setZoom(0.81);
      setCenter([16.847489977737535, -50.77916213593956]); // Updated coordinates for desktop
    }
    handleMoveEnd({ zoom: window.innerWidth <= 768 ? 1 : 0.81, coordinates: window.innerWidth <= 768 ? [12.971251597791488, -30.195707340023695] : [16.847489977737535, -50.77916213593956] });
};

  const sortCountriesAlphabetically = (countries) => {
    return countries.sort((a, b) => a.name.localeCompare(b.name));
  };

  const getFilteredCountries = () => {
    if (filter.tournament && filter.group) {
      return sortCountriesAlphabetically(countryGroups[filter.tournament][filter.group]);
    }
    if (filter.tournament) {
      let tournamentCountries = [];
      for (const group in countryGroups[filter.tournament]) {
        tournamentCountries = tournamentCountries.concat(countryGroups[filter.tournament][group]);
      }
      return sortCountriesAlphabetically(tournamentCountries);
    }
    let allCountries = [];
    for (const tournament in countryGroups) {
      for (const group in countryGroups[tournament]) {
        allCountries = allCountries.concat(countryGroups[tournament][group]);
      }
    }
    return sortCountriesAlphabetically(allCountries);
  };

  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const handleMoveEnd = debounce((position) => {
    setZoom(position.zoom);
    setCenter(position.coordinates);
  }, 100);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setSelectedCountry(null);
  };

  const handleZoomIn = () => {
    const newZoom = Math.min(zoom + 0.2, 10);
    setZoom(newZoom);
    handleMoveEnd({ zoom: newZoom, coordinates: center });
  };

  const handleZoomOut = () => {
    const newZoom = Math.max(zoom - 0.2, 0.5);
    setZoom(newZoom);
    handleMoveEnd({ zoom: newZoom, coordinates: center });
  };

  useLayoutEffect(() => {
    const mapContainer = mapContainerRef.current;

    if (!mapContainer) return;

    const handleScroll = (event) => {
      event.preventDefault();
      const zoomChange = event.deltaY > 0 ? -0.1 : 0.1;
      const newZoom = Math.max(0.5, Math.min(zoom + zoomChange, 10));
      setZoom(newZoom);
      handleMoveEnd({ zoom: newZoom, coordinates: center });
    };

    const handleTouchEnd = (event) => {
      if (!event.changedTouches || event.changedTouches.length === 0) {
        return;
      }
      const touch = event.changedTouches[0];
    };

    mapContainer.addEventListener("wheel", handleScroll);
    mapContainer.addEventListener("touchend", handleTouchEnd);

    mapContainer.scrollTop = 0;

    return () => {
      mapContainer.removeEventListener("wheel", handleScroll);
      mapContainer.removeEventListener("touchend", handleTouchEnd);
    };
  }, [zoom, center]);

  useEffect(() => {
    console.log('Current filter state:', filter);
    console.log('Current selected country:', selectedCountry);
  }, [filter, selectedCountry]);

  if (loading) return <p>Loading map...</p>;
  if (error) return <p>Error loading map: {error}</p>;

  return (
    <div className="map-container" ref={mapContainerRef}>
      <div className="filters-container">
        <div className="filters">
          <label>
            Tournament:
            <select
              value={filter.tournament || ""}
              onChange={(e) => handleFilterChange({ tournament: e.target.value || null, group: null, country: null })}
            >
              <option value="">Select</option>
              <option value="Copa America">Copa America</option>
              <option value="Euro">Euro</option>
            </select>
          </label>
          <label>
            Group:
            <select
              value={filter.group || ""}
              onChange={(e) => handleFilterChange({ ...filter, group: e.target.value || null, country: null })}
            >
              <option value="">Select</option>
              {filter.tournament && Object.keys(countryGroups[filter.tournament]).map(group => (
                <option key={group} value={group}>{group}</option>
              ))}
            </select>
          </label>
          <label>
            Country:
            <select
              value={filter.country || ""}
              onChange={(e) => {
                const selectedCountryIso = e.target.value || null;
                const countryInfo = getCountryInfo(selectedCountryIso);
                handleFilterChange({ ...filter, country: selectedCountryIso });
                if (countryInfo) {
                  setSelectedCountry({ iso: selectedCountryIso, ...countryInfo });
                } else {
                  setSelectedCountry(null);
                }
              }}
            >
              <option value="">Select</option>
              {getFilteredCountries().map(country =>
                <option key={country.iso} value={country.iso}>{country.name}</option>
              )}
            </select>
          </label>
        </div>
      </div>
      <div className="country-info-container">
        {selectedCountry && (
          <div className="country-info">
            <strong>{selectedCountry.name}</strong>
            <br />
            {selectedCountry.tournament} - {selectedCountry.group}
            <br />
            FIFA Rank: {selectedCountry.rank}
            <br />
            Population: {formatPopulation(selectedCountry.population)}
          </div>
        )}
      </div>
      <div className="buttons-container">
        <button onClick={() => handleFilterChange({ tournament: null, group: null, country: null })}>Clear Filter</button>
        <button onClick={handleResetZoom}>Reset Zoom</button>
      </div>
      <div className="map">
        <ComposableMap projectionConfig={{ rotate: [-10, 0, 0], scale: 160, center: [0, 0] }}>
          <ZoomableGroup
            zoom={zoom}
            center={center}
            minZoom={0.5}
            maxZoom={10}
            translateExtent={[
              [-1000, -1000],
              [1000, 1000]
            ]}
            onMoveStart={({ coordinates, zoom }) => {
              console.log("Move started with coordinates:", coordinates, "and zoom:", zoom);
            }}
            onMove={({ x, y, k, dragging }) => {
              console.log("Moving - x:", x, "y:", y, "k:", k, "dragging:", dragging);
            }}
            onMoveEnd={({ coordinates, zoom }) => {
              console.log("Move ended with coordinates:", coordinates, "and zoom:", zoom);
              handleMoveEnd({ coordinates, zoom });
            }}
            onTouchEnd={(event) => {
              try {
                console.log("Touch end detected", event);
                if (!event.changedTouches || event.changedTouches.length === 0) {
                  return;
                }
                const touch = event.changedTouches[0];
                if (touch) {
                  console.log(`Touch ended at (${touch.clientX}, ${touch.clientY})`);
                }
              } catch (error) {
                console.error("Error handling touch end:", error);
              }
            }}
          >
            <rect
              width="100%"
              height="100%"
              fill="transparent"
            />
            <Geographies geography={{ type: 'FeatureCollection', features: geographies }}>
              {({ geographies }) => {
                const selectedGeo = [];
                const otherGeos = [];

                geographies.forEach((geo) => {
                  const iso = geo.properties.ISO_A3;
                  const countryInfo = getCountryInfo(iso);
                  const isInTournament = countryInfo !== null;

                  if (selectedCountry && selectedCountry.iso === iso) {
                    selectedGeo.push(geo);
                  } else {
                    otherGeos.push(geo);
                  }
                });

                return [...otherGeos, ...selectedGeo].map((geo) => {
                  const iso = geo.properties.ISO_A3;
                  const color = getCountryColor(iso);
                  const countryInfo = getCountryInfo(iso);
                  const isInTournament = countryInfo !== null;
                  const deemphasized = shouldDeemphasize(iso);
                  const isInSelectedGroup = filter.tournament && filter.group
                    ? countryGroups[filter.tournament][filter.group].some(c => c.iso === iso)
                    : true;

                  let className = 'country';
                  if (isInTournament && !deemphasized) {
                    className += ' emphasized';
                  } else if (isInTournament && deemphasized) {
                    className += ' deemphasized';
                  }

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={color}
                      onMouseEnter={() => {
                        if (isInTournament && !deemphasized && isInSelectedGroup) {
                          setHoveredCountry({
                            iso,
                            ...countryInfo,
                          });
                        }
                      }}
                      onMouseLeave={() => setHoveredCountry(null)}
                      onClick={() => {
                        if (isInTournament && (!filter.tournament || isInSelectedGroup)) {
                          handleCountryClick(iso);
                        }
                      }}
                      className={className}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" }
                      }}
                    />
                  );
                });
              }}
            </Geographies>
            {/* Overlay for Scotland and England */}
            <Geographies geography={{ type: 'FeatureCollection', features: geographies }}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const iso = geo.properties.ADM0_TLC;
                  if (iso !== 'SCT' && iso !== 'ENG') return null;
                  const color = getCountryColor(iso);
                  const countryInfo = getCountryInfo(iso);
                  const isInTournament = countryInfo !== null;
                  const deemphasized = shouldDeemphasize(iso);
                  const isInSelectedGroup = filter.tournament && filter.group
                    ? countryGroups[filter.tournament][filter.group].some(c => c.iso === iso)
                    : true;

                  let className = 'country';
                  if (isInTournament && !deemphasized) {
                    className += ' emphasized';
                  } else if (isInTournament && deemphasized) {
                    className += ' deemphasized';
                  }

                  return (
                    <Geography
                      key={`${geo.rsmKey}-overlay`}
                      geography={geo}
                      fill={color}
                      onMouseEnter={() => {
                        if (isInTournament && !deemphasized && isInSelectedGroup) {
                          setHoveredCountry({
                            iso,
                            ...countryInfo,
                          });
                        }
                      }}
                      onMouseLeave={() => setHoveredCountry(null)}
                      onClick={() => {
                        if (isInTournament && (!filter.tournament || isInSelectedGroup)) {
                          handleCountryClick(iso);
                        }
                      }}
                      className={className}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" }
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <div className="zoom-buttons-container">
          <button className="zoom-button" onClick={handleZoomIn}>+</button>
          <button className="zoom-button" onClick={handleZoomOut}>-</button>
        </div>
      </div>
    </div>
  );
};

export default SimpleMap;
