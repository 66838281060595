import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Instructions.css';

const Instructions = ({ showButton }) => {
  const navigate = useNavigate();

  const handleStartPlaying = () => {
    console.log('Start Playing button clicked');
    navigate('/main-content/intro'); // Navigate to the main content intro page
  };

  return (
    <div className="instructions-container">
      <h2>Tournament Countries Introduction</h2> {/* Ensure the title text is correct */}
      <p>
        Get ready to follow along with the group play of the two 2024 summer Men's FIFA Tournaments: the Euro and Copa America!
      </p>
      <p>Here’s how to play:</p>
      <ol>
        <li>
          <strong>Explore the Tournaments:</strong> Learn about the countries participating in the tournaments and their groups.
        </li>
        <li>
          <strong>Make Your Predictions:</strong> Navigate to the prediction pages for Euro and Copa America. For each match, predict the scores for both teams.
        </li>
        <li>
          <strong>Submit Your Predictions:</strong> After making your predictions, <strong>submit</strong> them to lock in your choices.
        </li>
      </ol>
      <p><strong>Scoring System:</strong></p>
      <p>
        Ah we see you're here to win! Just as soccer is a game of margins, so is Shoot Score Win. Instead of simply picking winners or spreads, you will predict the exact results of all group stage games. Your picks will be graded on the following criteria:
      </p>
      <table className="scoring-table">
        <thead>
          <tr>
            <th>Criteria</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td><strong>Correct Exact Score</strong> (Regardless of teams)</td>
            <td>3 points</td>
          </tr>
          <tr>
            <td><strong>Correct Outcome (Win, Tie, Loss)</strong></td>
            <td>2 points</td>
          </tr>
          <tr>
            <td><strong>Correct Goal Differential</strong></td>
            <td>1 point</td>
          </tr>
        </tbody>
      </table>
      <p>
        There are many ways to be successful, craft your strategy however you like.
      </p>
      {showButton && (
        <button onClick={handleStartPlaying} className="start-playing-button">
          Start Playing
        </button>
      )}
    </div>
  );
};

export default Instructions;
