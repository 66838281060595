import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTable, useSortBy } from 'react-table';
import { AuthContext } from '../../context/AuthContext';
import './Leaderboard.css';
import RaceChart from './RaceChart'; // Import the RaceChart component

// Mapping of original tournament names to standardized names
const tournamentNameMap = {
    'Euro': 'UEFA Euro',
    'CONMEBOL Copa América': 'Copa America'
};

const standardizeTournamentName = (tournament) => {
    return tournamentNameMap[tournament] || tournament;
};

const originalTournamentName = (standardizedName) => {
    return Object.keys(tournamentNameMap).find(key => tournamentNameMap[key] === standardizedName) || standardizedName;
};

const Leaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);
    const [loadingTournaments, setLoadingTournaments] = useState(true);
    const [error, setError] = useState(null);
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState('Copa America');
    const { token } = useContext(AuthContext);
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchTournaments = async () => {
            if (!token) {
                setError('No token found');
                setLoadingTournaments(false);
                return;
            }

            try {
                const response = await fetch(`${baseUrl}/api/tournaments`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch tournaments');
                }

                const data = await response.json();
                console.log('Fetched Tournaments:', data);
                setTournaments(data.map(t => standardizeTournamentName(t.tournament)));
            } catch (err) {
                setError(`Failed to fetch tournaments: ${err.message}`);
            } finally {
                setLoadingTournaments(false);
            }
        };

        fetchTournaments();
    }, [token, baseUrl]);

    useEffect(() => {
        const fetchLeaderboard = async () => {
            if (!token) {
                setError('No token found');
                setLoadingLeaderboard(false);
                return;
            }

            try {
                const response = await fetch(`${baseUrl}/api/leaderboard-with-history?tournament=${encodeURIComponent(originalTournamentName(selectedTournament))}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch leaderboard');
                }

                const data = await response.json();
                console.log('Fetched Leaderboard:', data);

                const sortedData = data.sort((a, b) => b.total_points - a.total_points).map((entry, index) => ({
                    ...entry,
                    rank: index + 1,
                    total_points: entry.total_points || 0,
                    correct_scores: entry.correct_scores || 0,
                    correct_differentials: entry.correct_differentials || 0,
                    correct_winners: entry.correct_winners || 0,
                    number_of_picks: entry.number_of_picks || 0
                }));

                setLeaderboard(sortedData);
            } catch (err) {
                setError(`Failed to fetch leaderboard: ${err.message}`);
            } finally {
                setLoadingLeaderboard(false);
            }
        };

        fetchLeaderboard();
    }, [token, baseUrl, selectedTournament]);

    const columns = useMemo(() => [
        { Header: 'Rank', accessor: 'rank' },
        { Header: 'Username', accessor: 'username' },
        {
            Header: 'Total Points',
            accessor: 'total_points',
            Cell: ({ value }) => (
                <span>
                    {value}
                    <span className="tooltip-text">
                        Total points accrued:<br />
                        Correct Scores (3 pts),<br />
                        Correct Differentials (2 pts),<br />
                        Correct Outcome (1 pt)
                    </span>
                </span>
            ),
            getHeaderProps: (column) => column.getSortByToggleProps({ title: undefined })
        },
        {
            Header: 'Correct Scores',
            accessor: 'correct_scores',
            Cell: ({ value }) => (
                <span>
                    {value}
                    <span className="tooltip-text">3 points each</span>
                </span>
            ),
            getHeaderProps: (column) => column.getSortByToggleProps({ title: undefined })
        },
        {
            Header: 'Correct Differentials',
            accessor: 'correct_differentials',
            Cell: ({ value }) => (
                <span>
                    {value}
                    <span className="tooltip-text">2 points each</span>
                </span>
            ),
            getHeaderProps: (column) => column.getSortByToggleProps({ title: undefined })
        },
        {
            Header: 'Correct Outcome',
            accessor: 'correct_winners',
            Cell: ({ value }) => (
                <span>
                    {value}
                    <span className="tooltip-text">1 point each</span>
                </span>
            ),
            getHeaderProps: (column) => column.getSortByToggleProps({ title: undefined })
        },
        { Header: 'Number of Picks Submitted', accessor: 'number_of_picks' }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data: leaderboard }, useSortBy);

    if (loadingTournaments || loadingLeaderboard) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="leaderboard-container">
            <h2>Leaderboard</h2>
            <div className="tabs">
                {tournaments.map(tournament => (
                    <button
                        key={tournament}
                        className={`tab ${selectedTournament === tournament ? 'active' : ''}`}
                        onClick={() => setSelectedTournament(tournament)}
                    >
                        {tournament}
                    </button>
                ))}
            </div>
            <table {...getTableProps()} className="leaderboard">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                    </span>
                                    {index >= 2 && index <= 5 && (
                                        <span className="tooltip-text">
                                            {column.Header === 'Total Points' && 'Total points accrued:<br />Correct Scores (3 pts),<br />Correct Differentials (2 pts),<br />Correct Outcome (1 pt)'}
                                            {column.Header === 'Correct Scores' && '3 points each'}
                                            {column.Header === 'Correct Differentials' && '2 points each'}
                                            {column.Header === 'Correct Outcome' && '1 point each'}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div style={{ width: '100%', height: '400px', marginTop: '20px' }}>
                <RaceChart leaderboard={leaderboard} /> {/* Add RaceChart here */}
            </div>
        </div>
    );
};

export default Leaderboard;
