import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Tabs = ({ children }) => { // Removed progress from props
    const [activeTab, setActiveTab] = useState(children[0].props.label);
    const { token } = useContext(AuthContext);

    const handleClick = (newActiveTab) => {
        setActiveTab(newActiveTab);
    };

    return (
        <div>
            {/* Removed the fixed-progress-bar element */}
            <div className="tab-buttons">
                {React.Children.map(children, (child) => (
                    <button
                        key={child.props.label}
                        onClick={() => handleClick(child.props.label)}
                        className={child.props.label === activeTab ? 'tab active' : 'tab'}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {React.Children.map(children, (child) => {
                    if (child.props.label === activeTab) {
                        return <div key={child.props.label}>{child.props.children}</div>;
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default Tabs;
