import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Effect triggered');
    console.log('Success message state updated:', successMessage);
    console.log('Error message state updated:', errorMessage);
  }, [successMessage, errorMessage]);

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log('Submit button clicked');
    setErrorMessage('');
    setSuccessMessage('');
    setIsSubmitting(true); // Disable the submit button

    const payload = { username, email, password };
    console.log('Register payload:', payload); // Log the payload being sent

    try {
      console.log('Sending API request to /register');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      console.log('Register response status:', response.status); // Log the status of the response
      const data = await response.json();
      console.log('Register response data:', data); // Log the data of the response

      if (response.ok) {
        console.log('Registration successful:', data);
        setSuccessMessage(data.message || 'Account created successfully.');
        console.log('Success message set:', data.message || 'Account created successfully.');
        setIsSubmitting(false); // Re-enable the submit button for further tests
        console.log('Current successMessage state after setting:', successMessage);

        // Delay navigation to ensure the message is seen
        setTimeout(() => {
          console.log('Redirecting to /login');
          navigate('/login');
        }, 3000); // Redirect after 3 seconds
      } else {
        console.error('Error response data:', data);
        setErrorMessage(data.message || 'Registration failed. Please try again.');
        console.log('Error message set:', data.message || 'Registration failed. Please try again.');
        setIsSubmitting(false); // Re-enable the submit button
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
      console.log('Error message set: An unexpected error occurred. Please try again.');
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  console.log('Rendering Register component');
  console.log('Current successMessage:', successMessage);
  console.log('Current errorMessage:', errorMessage);

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleRegister}>
        <h2>Register</h2>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="auth-error">{errorMessage}</div>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isSubmitting}>Register</button>
      </form>
      <button
        className="back-button"
        onClick={() => {
          console.log('Back button clicked');
          navigate('/login');
        }}
      >
        Back
      </button>
    </div>
  );
};

export default Register;
