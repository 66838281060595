import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Auth.css';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'; // Ensure correct port here

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(isLogin ? 'Login button clicked' : 'Submit button clicked');
    setMessage(''); // Clear previous messages
    const url = isLogin ? `${baseUrl}/login` : `${baseUrl}/register`;
    const body = isLogin ? { email, password } : { username, email, password };
    console.log('Submitting to URL:', url);
    console.log('Submitting body:', body);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include', // Add this line
      });
      const data = await response.json();
      console.log('Response status:', response.status);
      console.log('Response data:', data);
      if (response.ok) {
        if (isLogin) {
          login(data.accessToken);
          navigate('/instructions');
        } else {
          setMessage('Account created successfully.<br /> Proceeding to login.');
        }
      } else {
        setMessage(data.message); // Set error message
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setMessage('An unexpected error occurred. Please try again.'); // Set generic error message
    }
  };

  useEffect(() => {
    if (message.includes('Account created successfully')) {
      const timer = setTimeout(() => {
        setIsLogin(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="auth-container">
      <div className="auth-title">Shoot Score Win</div>
      <form onSubmit={handleSubmit} className="auth-form">
        {message && <div className={message.includes('successfully') ? 'success-message' : 'auth-error'} dangerouslySetInnerHTML={{ __html: message }}></div>} {/* Display message */}
        {!isLogin && (
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        )}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
        <div className="auth-links">
          <button type="button" onClick={() => {
            console.log(isLogin ? 'Register button clicked' : 'Login button clicked');
            setIsLogin(!isLogin);
          }}>
            {isLogin ? 'Register' : 'Login'}
          </button>
          {isLogin && (
            <button type="button" onClick={() => navigate('/request-password-reset')}>
              Forgot Password?
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Auth;
