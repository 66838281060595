import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({ progress }) => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const progressBar = document.querySelector('.progress-bar');
      const progressContainer = document.querySelector('.progress-container');

      if (!progressBar || !progressContainer) {
        return;
      }

      const rect = progressContainer.getBoundingClientRect();
      const shouldBeFixed = rect.top <= 0;

      if (shouldBeFixed !== isFixed) {
        setIsFixed(shouldBeFixed);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed]);

  return (
    <div className={`progress-bar ${isFixed ? 'fixed' : ''}`}>
      <div className="progress" style={{ width: `${progress}%` }}>
        {progress}%
      </div>
    </div>
  );
};

export default ProgressBar;
